import React from 'react';
import './App.css';
import NappMapPage from './pages/map.page';
import NappLandingPage from './pages/landing.page';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import * as ROUTES from './routes';
import { withAuthentication } from './session';
import AccountPage from './pages/profile.page';
import SignInPage from './pages/signin.page';
import SignUpPage from './pages/signup.page';
import PasswordForgetPage from './pages/passwordforgot.page';
import WatersPage from './pages/waters.page';
import WaterDetailPage from './pages/water_detail.page';
import NappToast from './components/toaster';
import NappModal from './components/modal';
import NappEula from './components/eula';
import ChangelogPage from './pages/changelog.page';
import { compose } from 'recompose';
import MembershipPage from './pages/membership.page';

class RouteContainerComponent extends React.Component {
  showBgColorRoutes = [ROUTES.SIGN_IN, ROUTES.SIGN_UP, ROUTES.PASSWORD_FORGET, ROUTES.MEMBERSHIP];

  componentDidMount() {
    this.toggleBgColor(window.location);
    this.listener = this.props.history.listen((location) => {
      this.toggleBgColor(location);
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  toggleBgColor(location) {
    if (this.showBgColorRoutes.indexOf(location.pathname) > -1) {
      this.useBgColor();
    } else {
      this.removeBgColor();
    }
  }

  useBgColor = (event) => document.body.classList.add('secondary-bgcolor');
  removeBgColor = (event) => document.body.classList.remove('secondary-bgcolor');

  render() {
    return <div className="App">
      {this.props.children}
    </div>
  }
}

const RouteContainer = compose(
  withRouter,
  withAuthentication
)(RouteContainerComponent);

class App extends React.Component {
  render() {
    return (
      <Router>
        <RouteContainer>
          <Route exact path={ROUTES.LANDING} component={NappLandingPage} />
          <Route exact path={ROUTES.MAP} component={NappMapPage} />
          <Route exact path={ROUTES.PROFILE} component={AccountPage} />
          <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route exact path={ROUTES.EULA} component={NappEula} />
          <Route exact path={ROUTES.WATERS} component={WatersPage} />
          <Route exact path={ROUTES.CHANGE_LOG} component={ChangelogPage} />
          <Route exact path={ROUTES.MEMBERSHIP} component={MembershipPage} />
          <Route path={ROUTES.WATER_DETAILS} component={WaterDetailPage} />
          <NappToast />
          <NappModal />
        </RouteContainer>
      </Router>
    );
  }
}
export default compose(
  withAuthentication,
)(App);
