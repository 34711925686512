import React from 'react';
import { withAuthorization, AuthUserContext } from '../session';
import NavBar from '../components/navbar';
import PasswordChangeForm from './passwordchange.page';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <NavBar />
        <div style={{ marginTop: 50, padding: 20 }}>
          <h1>Min Profil: {authUser.email}</h1>
          <div style={{ maxWidth: 400 }}>
            <div>
              <h3>Endre passord</h3>
              <div style={{ marginLeft: -5 }}>
                <PasswordChangeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);