import React from 'react';
import ReactMapboxGl, { Feature, Layer, Popup } from 'react-mapbox-gl';

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYW5kZXJzaGFnZWJha2tlbiIsImEiOiJ5dWxXMDZ3In0.FOBBIiCSUmSahrePOKwqWg'
});

const Callout = ({ item }) => (
  !!item && !!item.title ? <div style={{ padding: 0 }}>
    <p>{item.title}</p>
  </div> : null
);

const getMapCenter = (currentCenter, markers) => {
  if (markers.length === 1) return markers[0].location;
  if (!currentCenter) {
    let lat = 0;
    let lng = 0;
    markers.forEach(m => {
      lng += m.location[0];
      lat += m.location[1];
    });
    return [lng / markers.length, lat / markers.length];
  }

  return currentCenter;
}

export { getMapCenter };

class NMapComponent extends React.Component {



  render() {
    let { center, markers = [], onClick = () => { }, zoom = 10 } = this.props;
    markers = [].concat(markers).filter(m => !!m.location);
    center = getMapCenter(center, markers);
    return (
      <div style={{ flex: 1, height: '100%', width: '100%' }}>
        <Map
          onClick={onClick}
          zoom={[zoom]}
          center={!!center ? center : null}
          style="mapbox://styles/mapbox/streets-v8"
          containerStyle={{
            height: '100%',
            minHeight: '100%',
            width: '100%',
            minWidth: '100%'
          }}>
          <Layer
            type="circle"
            paint={{ "circle-color": "#116466", 'circle-radius': 15, 'circle-stroke-color': 'black', 'circle-stroke-width': 2, 'circle-opacity': 0.8 }}>
            {markers.map((marker, i) => {
              return <Feature key={"feature" + marker.id} coordinates={marker.location} />
            })}
          </Layer>
          {markers.map(marker => {
            return !!marker.title ? <Popup
              key={"popup" + marker.id}
              onClick={() => { }}
              style={{ maxWidth: 400, padding: 5 }}
              coordinates={marker.location}
              anchor={'bottom'}>
              <Callout item={marker} />
            </Popup> : null
          })}
        </Map>
      </div>
    );
  }
}

export default NMapComponent;