import React from 'react';
import { NappIcon } from '../components/icon';
import * as ROUTES from '../routes';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthentication } from '../session';
import NavBar from '../components/navbar';

const NappLandingPageComponent = ({ authUser }) => {
  return (
    <div className="landing-page">
      {/*<div style={{ height: 50, width: '100%', position: 'fixed', top: 0, backgroundColor: '#D1E8E2' }}>
          <ul style={{ display: 'flex', flexDirection: 'row', listStyleType: 'none' }}>
            <li style={{ marginRight: 30, marginLeft: 30 }}>Info</li>
            <li style={{ marginRight: 30 }}>Kontakt</li>
          </ul>
        </div>*/}
      <NavBar />
      <div style={{ display: 'flex', flexDirection: 'column' }} className="primary-bgcolor">
        <div style={{ flex: 1, minHeight: 300, padding: 50, flexWrap: 'wrap-reverse', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }} className="primary-bgcolor landing-page__item">
          <div style={{ flex: 1, minWidth: 350 }}>
            <h1 style={{ fontSize: 90 }}><img alt="skog" style={{ maxHeight: 100, width: 'auto' }} src="/fishing-pole.png" />napp</h1 >
            <h3>Leter du etter nye fiskesteder?</h3>
            <p>Det er ikke alltid lett å finne ut hvordan man kan komme seg frem til fiskevann, og hvor det er mulig å parkere. Napp ønsker å gjøre det lettere for ivrige fiskere å finne frem til nye fiskeplasser.</p>
            {!!!authUser ? <Link to={ROUTES.SIGN_UP}><button className="button secondary-bgcolor button-large">Ny bruker</button></Link> : null}
            {!!!authUser ? <Link to={ROUTES.SIGN_IN}><button className="button secondary-bgcolor button-large">Logg inn</button></Link> : null}
            {!!authUser ? <Link to={ROUTES.MAP}><button className="button secondary-bgcolor button-large">Gå til kart</button></Link> : null}
          </div>
          <div style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', padding: 20 }}>
            <img alt="skog" style={{ maxHeight: 350, minWidth: 300, maxWidth: '100%', height: 'auto', flex: 1 }} src="/frontpage1-min.png" />
          </div>
        </div>
        <div style={{ flex: 1, minHeight: 350, display: 'flex', flexDirection: 'column', paddingTop: 50, paddingBottom: 50 }} className="secondary-bgcolor landing-page__item">
          <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: 1, minWidth: 270 }}>
              <h1>FIskekort</h1>
              <NappIcon title="Fiskekort" name="money-bill" size={40} color="white" />
              <p>Hvor får man kjøpt fiskekort?</p>
            </div>
            <div style={{ flex: 1, minWidth: 270 }}>
              <h1>Ankomst</h1>
              <NappIcon title="Bil" name="car" size={40} color="white" />
              <p>Hvor kan jeg parkere? Er det bomvei? </p>
            </div>
            <div style={{ flex: 1, minWidth: 270 }}>
              <h1>Regler</h1>
              <NappIcon title="Regler" name="list-alt" size={40} color="white" />
              <p>Hvilke fiskeregler er det for vannet?</p>
            </div>
            <div style={{ flex: 1, minWidth: 270 }}>
              <h1>Arter</h1>
              <NappIcon title="Regler" name="fish" size={40} color="white" />
              <p>Hvilke arter finnes i fiskevannet?</p>
            </div>
          </div>
          <div style={{ flex: 1, display: 'flex', textAlign: 'center', width: '100%' }}>
            <h3 style={{ width: '100%' }}>Alt dette kan du muligens finne svar på vårt oversiktskart.</h3>
          </div>
        </div>
        <div style={{ flex: 1, minHeight: 200, paddingTop: 50, backgroundColor: 'white', paddingLeft: '10%', paddingRight: '10%' }} className="primary-color landing-page__item">
          <h1>Vi er i en tidlig fase</h1>
          <h3>Napp er avhengig av at ivrige kjentsfolk legger inn informasjon om fiskesteder. Hvis du har informasjon om fiskesteder, send oss informasjonen så vi kan få delt det med andre ivrige fiskere.</h3>
          <h3>For øyeblikket har vi ca 20 vann i Akershus. Vi setter stor pris på alle bidrag!</h3>
        </div>
        <div style={{ flex: 1, minHeight: 300 }} className="secondary-bgcolor landing-page__item">
          <h1>Opprett en bruker</h1>
          <h3>Hvorfor opprette en bruker? Du får muligheten til blant annet:</h3>
          <p>Legg inn informasjon om fiskevann</p>
          <p>Kommentere på fiskevann</p>
          <p>Opprette favorittlister</p>
          <p>... og mye mer</p>
        </div>
        <div style={{ flex: 1, minHeight: 300 }} className="primary-bgcolor landing-page__item">
          <h1>Om</h1>
          <p>Napp blir utviklet etter ønske fra flere lokale fiskeforeninger. <br />Her har man muligheten til å finne informasjon om flere vann på en nettside.</p>
          <div style={{ minHeight: 50 }}></div>
          <p>Siden er utviklet av <a href="https://andmark.no" target="_blank" rel="noopener noreferrer">AndMark AS</a> &copy; {new Date().getFullYear()}</p>
          <p><a style={{ color: 'white' }} href="/endringslogg">Se endringslogg</a></p>
        </div>
      </div>
    </div>
  )
}

const NappLandingPage = compose(
  withRouter,
  withAuthentication,
)(NappLandingPageComponent);

export default NappLandingPage;