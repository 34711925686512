import { firebaseConfig } from './config';
import firebase from 'firebase/app';
import { GeoFirestore } from 'geofirestore';
import { NappAuthError } from './firebaseAuthError';
import 'firebase/auth';
import 'firebase/firestore';

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    const geofirestore = new GeoFirestore(this.db);
    this.geoCollection = geofirestore.collection('waters');
    this.userRole = 'user';
    this.init();
    this.userRef = undefined;
    this.userId = undefined;
  }

  async init() {
    this.listener = this.auth.onAuthStateChanged(async authUser => {
      try {
        if (!!authUser) {
          this.userRef = this.getUserRefById(authUser.uid);
          this.userId = authUser.uid;
          const userDoc = await this.userRef.get();
          const user = userDoc.data();
          this.userRole = !!user ? user.role : 'user';
        } else {
          this.userRole = undefined;
          this.userId = undefined;
        }
      } catch (e) {
        this.userRole = undefined;
        this.userId = undefined;
      }
    });
  }

  // *** Auth ***
  createUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password).catch(e => { throw new NappAuthError(e.code); });
  signInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password).catch(e => { throw new NappAuthError(e.code); });
  signOut = async () => { await this.auth.signOut(); window.location.href = "/"; };
  passwordReset = email => this.auth.sendPasswordResetEmail(email).catch(e => { throw new NappAuthError(e.code); });
  passwordUpdate = password => this.auth.currentUser.updatePassword(password).catch(e => { throw new NappAuthError(e.code); });
  // *** End Auth ***


  getCollection = (name) => this.db.collection(name);

  getDoc = async (collectionName, id) => {
    const doc = await this.getCollection(collectionName).doc(id).get();
    if (!doc.exists) return null;
    return Object.assign({}, { id: doc.id, ...doc.data() });
  }

  getDocs = async (collectionName) => {
    const data = await this.getCollection(collectionName).get();
    return data.docs.map(d => Object.assign({ id: d.id, ...d.data() }));
  }

  // *** Users *** //
  getUserRole = () => this.userRole;

  getUserRefById = (id) => this.db.collection(`users`).doc(id);
  getUserRef = () => this.userRef;
  getUserId = () => this.userId;
  // *** End Users *** //

  // *** Waters *** //
  getWater = async (id) => {
    const doc = await this.getWaterRef(id).get();
    if (!doc.exists) return null;
    const water = Object.assign({ id: doc.id, ...doc.data() });
    if (water.user !== this.getUserId()) {
      throw new Error('Du har ikke tilgang til å redigere fiskeplassen');
    }
    return water;
  }
  getWaterRef = id => this.geoCollection.doc(id);
  createWater = (data) => this.geoCollection.add(data);
  deleteWater = id => this.geoCollection.doc(id).delete();
  updateWater = (id, data) => {
    const body = { ...data };
    delete body.id;
    return this.geoCollection.doc(id).update(body);
  }
  getWaters = async () => {
    const data = await this.geoCollection.get();
    return data.docs.map(d => Object.assign({ id: d.id, ...d.data() }));
  }
  getMyWaters = async () => {
    const data = await this.geoCollection.where('user', '==', this.userId).limit(100).get();
    return data.docs.map(d => Object.assign({ id: d.id, ...d.data() }));
  }
  searchForWaters = async (searchText) => {
    const data = await this.db.collection("waters").where('d.searchable', '==', searchText).limit(5).get();
    return data.docs.map(d => Object.assign({ id: d.id, ...d.data().d }));
  }
  getClosestWaters = async (lng, lat, radius = 50) => {
    const data = await this.geoCollection.near({ center: new firebase.firestore.GeoPoint(lng, lat), radius }).get();
    return data.docs.map(d => Object.assign({ id: d.id, ...d.data() }));
  }
  // *** End Waters *** //
}
export default Firebase;