import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../routes';
import SignOutButton from '../components/signout';
import { withAuthentication } from '../session';
import { withRouter } from 'react-router-dom';
import '../css/navbar.css';

class NavBar extends React.Component {
  state = { open: false };
  render() {
    const { authUser, location } = this.props;
    if (!!authUser) {
      return (
        <div className="top_bar">
          <div className={"navbar " + (this.state.open ? 'responsive' : '')} style={{ display: 'flex', flexDirection: 'row', listStyleType: 'none', justifyContent: 'center', verticalAlign: 'middle' }}>
            <div className="nav-item hamburger" onClick={() => { this.setState({ open: !this.state.open }) }}>
              <i className="fa fa-bars" style={{ color: 'white' }}></i>
            </div>
            <div className={"navbar-content " + (this.state.open ? 'responsive' : '')}>
              <div className="nav-item">
                <Link to={ROUTES.MAP}>Kart</Link>
              </div>
              <div className="nav-item">
                <Link to={ROUTES.PROFILE}>Profil</Link>
              </div>
              <div className="nav-item">
                <a href={ROUTES.WATERS}>Mine Vann</a>
              </div>
              {authUser ? <div className="nav-item">
                <SignOutButton />
              </div> : null}
            </div>
          </div>
        </div >
      )
    } else {
      return <div className="top_bar">
        <div className={"navbar-content"}>
          {location.pathname !== '/' ?
            <div className="nav-item">
              <Link to={ROUTES.LANDING}>Forsiden</Link>
            </div> : null}
          <div className="nav-item">
            <Link to={ROUTES.SIGN_IN}>Logg inn</Link>
          </div>
          <div className="nav-item">
            <Link to={ROUTES.SIGN_UP}>Ny bruker</Link>
          </div>
        </div>
      </div >
    }
  }
}

export default withAuthentication(withRouter(NavBar));