import React from 'react';
import { withAuthorization } from '../session';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import NavBar from '../components/navbar';
import { compose } from 'recompose';
import { NappButton } from '../components/button';
import * as ROUTES from '../routes';
import NMapComponent from '../components/map';
import { NappToaster } from '../components/toaster';
import { NappIcon } from '../components/icon';

const ChangeLogPage = (props) => (
  <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', backgroundColor: 'white' }}>
    <NavBar />
    <ChangeLogPageComponent {...props} />
  </div>
);

class ChangeLogPageComponent extends React.Component {
  state = { data: [], loading: true };
  async componentDidMount() {
    try {
      const data = await this.props.firebase.getDocs('change_log');
      this.setState({ data, loading: false });
    } catch (e) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading, data } = this.state;
    return (
      <div style={{ padding: 20, marginTop: 30, width: '100%' }}>
        <h1>Endringslogg</h1>
        {loading ? <p>Henter endringslogg...</p> : null}
        <ul>
          {!!data && data.length ? data.map(d => {
            return (
              <li key={d.id}><span style={{ fontWeight: 'bold' }} className="secondary-color">{d.id}</span>
                <ul style={{ marginBottom: 20 }}>
                  {d.changes.map((c, j) => <li key={"" + j}>{c}</li>)}
                </ul>
              </li>
            )
          }) : null}
        </ul>
      </div>
    )
  }
}

export default compose(
  withFirebase,
  withRouter
)(ChangeLogPage);