import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import * as ROUTES from '../routes';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import NappLogo from '../components/logo';
import { NappModalHelper } from '../components/modal';
import NappEula from '../components/eula';
import NappErrorMessage from '../components/errormsg';

const SignUpPage = () => (
  <div className="sign_in__container">
    <NappLogo />
    <h1 style={{ color: 'white' }}>Ny bruker</h1>
    <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 5 }}>
      <SignUpForm />
      <CancelSignupLink />
    </div>
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  eula: false
};

class SignUpFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onSubmit = this.onSubmit.bind(this);
    this.showEula = this.showEula.bind(this);
  }

  async onSubmit(event) {
    const { username, email, passwordOne } = this.state;
    event.preventDefault();
    try {
      const authUser = await this.props.firebase.createUserWithEmailAndPassword(email, passwordOne);
      await this.props.firebase.userRef(authUser.user.uid).set({ name: username, email });
      this.setState({ ...INITIAL_STATE });
      this.props.history.push(ROUTES.MAP);
    } catch (e) {
      console.log(e);
      this.setState({ error: e });
    }
  };

  onChange = event => {
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  showEula = event => {
    event.preventDefault();
    NappModalHelper.show("Brukervilkår", <NappEula />);
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      eula
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      eula === false ||
      username === '';

    return (
      <form onSubmit={this.onSubmit} className="form">
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Navn"
        />
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          autoComplete="username"
          placeholder="E-postadresse"
        />
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          autoComplete="new-password"
          placeholder="Passord"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          autoComplete="new-password"
          placeholder="Gjenta passord"
        />
        <label style={{ marginTop: 10, marginBottom: 10 }}><input
          name="eula"
          value={eula}
          onChange={this.onChange}
          checked={eula}
          type="checkbox"
        /> Jeg godtar <a href={ROUTES.SIGN_UP} onClick={this.showEula}>brukervilkårene</a></label>
        <NappErrorMessage error={error} />
        <button disabled={isInvalid === true} type="submit" className="button button-primary">Opprett bruker</button>
      </form>
    );
  }
}

const SignUpLink = () => (
  <p className="secondary-color">
    Har du ikke bruker? <Link to={ROUTES.SIGN_UP}>Opprett bruker</Link>
  </p>
);

const CancelSignupLink = () => (
  <p>
    <Link to={ROUTES.LANDING}>Tilbake til hovedsiden</Link>
  </p>
);

export default SignUpPage;

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormComponent);

export { SignUpForm, SignUpLink, CancelSignupLink };