import React from 'react';
import reduxStore from '../redux/store';
import { connect } from 'react-redux';

const NappToast = (props) => {
  const { show, message, type } = props.toast;
  return <div className={"toast " + type + "-bg " + (show ? "toast-show" : "toast-hide")}>
    <p>{message}</p>
  </div>
}

const mapStateToProps = (state) => {
  return {
    toast: state.toaster.toast
  };
}

export default connect(mapStateToProps)(NappToast);

const NappToaster = {
  show: (message, type = "success", duration = 5000) => {
    if (type === "success" && duration === 5000) {
      duration = 3000;
    }
    reduxStore.dispatch({ type: "SHOW_TOAST", payload: { type, message } });
    setTimeout(() => {
      NappToaster.hide()
    }, duration);
  },
  hide: () => {
    reduxStore.dispatch({ type: "HIDE_TOAST" })
  }
}

export { NappToaster };