import { getDistance } from 'geolib';

const LocationHelper = {
  getCurrentLocation: () => {
    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        return resolve(null);
      }

      navigator.geolocation.getCurrentPosition((position) => {
        if (!!position && !!position.coords && !!position.coords.latitude && !!position.coords.longitude) {
          return resolve([position.coords.longitude, position.coords.latitude]);
        }
        return resolve(null);
      }, (error) => {
        console.log(error);
        return resolve(null);
      });
    });
  },
  getDistance: (from, to, accuracy = 100) => getDistance(from, to, accuracy)
}

export default LocationHelper;