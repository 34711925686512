import React from 'react';
import reduxStore from '../redux/store';
import { connect } from 'react-redux';
import { NappButton } from './button';

const NappModal = (props) => {
  const { content, title, show = false } = props.modal;
  return <div className={"modal-container " + (show ? "modal-show" : "modal-hide")}>
    <div className={"modal " + (show ? "modal-show" : "modal-hide")}>
      <h1>{title}</h1>
      <div className="modal-content">{content}</div>
      <div><NappButton title="Lukk" /></div>
    </div>
    <div className="modal-backdrop"></div>
  </div>
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal.modal
  };
}

export default connect(mapStateToProps)(NappModal);

const NappModalHelper = {
  show: (title, content) => {
    Object.assign(document.createElement('a'), { target: '_blank', href: '/eula' }).click();
  },
  hide: () => {
    //reduxStore.dispatch({ type: "HIDE_MODAL" })
  }
}

export { NappModalHelper };