import React from 'react';
import { withAuthorization, AuthUserContext } from '../session';
import { withRouter } from 'react-router-dom';
import NavBar from '../components/navbar';
import PasswordChangeForm from './passwordchange.page';
import { CancelSignupLink } from './signup.page';
import { NappButton } from '../components/button';

const MembershipPage = () => (
  <div className="sign_in__container">
    <h1 style={{ color: 'white' }}>Opprett medlemskap</h1>
    <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 5 }}>
      <div style={{ padding: 20, borderRadius: 5 }}>
        <h2 style={{ fontWeight: 'bold', margin: 0 }} className="primary-color" >For kun 29,- pr mnd!</h2>
        <p style={{ fontSize: 10 }} className="primary-color">348,- pr år. Forskuddsbetaling for et år er 290,-</p>
      </div>
      <h3 className="primary-color">Hvorfor opprette medlemskap?</h3>
      <ul className="secondary-color" style={{ listStyleType: 'none', fontWeight: 'bold', fontSize: 13, marginBottom: 50 }}>
        <li>Kommentere på fiskeplaser</li>
        <li>Opprett favorittlister</li>
        <li>Opprett private fiskeplasser</li>
        <li>Opprett private klubber du kan dele private plasser med</li>
        <li>Også vil Markus fylle på med flere fordeler</li>
      </ul>
      <NappButton title="Opprett medlemskap" style={{ width: '100%' }} onClick={() => { }} />
      <CancelSignupLink />
    </div>
  </div>
);

export default withRouter(MembershipPage);