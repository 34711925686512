import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { SignUpLink, CancelSignupLink } from './signup.page';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../routes';
import { PasswordForgetLink } from './passwordforgot.page';
import NappLogo from '../components/logo';
import { NappIcon } from '../components/icon';
import NappErrorMessage from '../components/errormsg';

const SignInPage = () => (
  <div className="sign_in__container">
    <NappLogo />
    <h1 style={{ color: 'white' }}>Logg inn</h1>
    <div style={{ backgroundColor: 'white', padding: 20, borderRadius: 5 }}>
      <SignInForm />
      <PasswordForgetLink />
      <SignUpLink />
      <CancelSignupLink />
    </div>
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        window.location.href = ROUTES.MAP;
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <form onSubmit={this.onSubmit} className="form">
        <div className="form-group">
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="E-postadresse"
          />
        </div>
        <div className="form-group" style={{ position: 'relative' }}>
          <input
            style={{ paddingRight: 40 }}
            name="password"
            value={password}
            onChange={this.onChange}
            type={!this.state.showPassword ? "password" : "text"}
            placeholder="Passord"
          />
          <NappIcon
            name={!this.state.showPassword ? "eye" : "eye-slash"}
            onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }}
            size={7.5}
            style={{ cursor: 'pointer', position: 'absolute', right: 15, top: 10 }} />
        </div>
        <NappErrorMessage error={error} />
        <button disabled={isInvalid} type="submit" className="button button-primary">
          Logg inn
        </button>
      </form>
    );
  }
}
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
export default SignInPage;
export { SignInForm };