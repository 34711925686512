import React from 'react';
import ReactMapboxGl, { Layer, Feature, Popup } from 'react-mapbox-gl';
import { NappIcon } from '../components/icon';
import { withFirebase } from '../Firebase';
import NavBar from '../components/navbar';
import { NappButton } from '../components/button';
import { getMapCenter } from '../components/map';
import * as ROUTES from '../routes';
import { Link } from 'react-router-dom';
import LocationHelper from '../locationhelpler';
import { withAuthorization } from '../session';
import '../css/searchbar.css';

const Map = ReactMapboxGl({
  accessToken: 'pk.eyJ1IjoiYW5kZXJzaGFnZWJha2tlbiIsImEiOiJ5dWxXMDZ3In0.FOBBIiCSUmSahrePOKwqWg',
  minZoom: 7
});

const defaultCenter = [11.340581, 60.123601];
const DEFAULT_ZOOM = 11;
const MAX_RADIUS = 1000;

class NappMapPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      center: [].concat(defaultCenter),
      active: null,
      currentMousePosition: null,
      radius: 50, //km
      zoom: DEFAULT_ZOOM
    };
    this.onRightClick = this.onRightClick.bind(this);
    this.onZoomEnd = this.onZoomEnd.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.markersIds = {};
    this.previousCenter = { latitude: 0, longitude: 0 };
    this.previousUserLocation = null;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getWaters();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getWaters(resetZoomToUserLocation = true, latLng = null) {
    try {

      let loc = [0, 0];
      if (!latLng && !this.previousUserLocation) {
        loc = await LocationHelper.getCurrentLocation();
        if (!loc) {
          throw new Error('Kunne ikke hente ut posisjonen din...');
        }
        this.previousUserLocation = loc;
      } else {
        loc = latLng === null ? this.previousUserLocation : latLng;
      }

      let waters = await this.props.firebase.getClosestWaters(loc[0], loc[1], this.state.radius);
      waters = waters.filter(w => {
        if (!!w.location && !!!this.markersIds[w.id]) {
          this.markersIds[w.id] = true;
          return w;
        }
        return false;
      });
      let markers = waters.map(w => <Feature key={w.id} coordinates={w.location} onClick={() => this.handleClick(w)} />);
      let center = getMapCenter(null, waters);
      if (loc !== null && resetZoomToUserLocation) {
        center = loc;
      }

      if (!resetZoomToUserLocation) {
        center = this.state.center;
      }

      markers = markers.concat(this.state.markers);
      this.setState({ markers, center });
    } catch (e) {
      console.log('e', e);
    }
  }

  handleClick = (item) => {
    this.setState({ active: item });
    if (item !== null) {
      this.setState({ center: item.location });
    }
  }

  onRightClick(map, event) {
    event.preventDefault();
    const { lat, lng } = event.lngLat;
    this.setState({ showContextMenu: true, currentMousePosition: { location: [lng, lat], point: event.point } });
  }

  onZoomEnd(map, event) {
    if (!!event && typeof event.preventDefault === 'function')
      event.preventDefault();

    let zoom = Math.round(map.getZoom());
    let { radius } = this.state;
    // default 11
    // zoom 12 = closer
    // zoom 10 = further

    // Do not go below 50KM in distance
    if (zoom > DEFAULT_ZOOM) {
      zoom = DEFAULT_ZOOM;
    }

    if (zoom < DEFAULT_ZOOM) {
      zoom = DEFAULT_ZOOM - zoom;
      radius = zoom * 50;
    }

    if (radius > MAX_RADIUS) {
      radius = MAX_RADIUS;
    }

    this.setState({ zoom, radius }, () => {
      this.getWaters(false);
    });
  }

  onDragEnd(map, event) {
    if (!!event && typeof event.preventDefault === 'function')
      event.preventDefault();

    const { lng, lat } = map.getCenter();
    const distance = LocationHelper.getDistance({ latitude: lat, longitude: lng }, this.previousCenter);
    // Update only when distance is atleast 10km, not spamming db too much. Might tweak this
    if (distance > 10000) {
      this.getWaters(false, [lng, lat]);
      this.previousCenter = { latitude: lat, longitude: lng };
      console.log(distance);
    }
  }

  onSearchClick(selected) {
    if (!!!this.markersIds[selected.id]) {
      const temp = [].concat(this.state.markers);
      temp.push(<Feature key={selected.id} coordinates={selected.location} onClick={() => this.handleClick(selected)} />);
      this.markersIds[selected.id] = true;
      this.setState({ markers: temp });
    }
    this.setState({ active: selected, center: selected.location });
  }

  render() {
    const { active, markers, center, currentMousePosition } = this.state;
    return <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', overflow: 'hidden' }}>
      <NavBar />
      <SearchBar onClick={this.onSearchClick} />
      {this.state.showContextMenu ? <ContextMenu onClick={() => this.setState({ showContextMenu: false })} position={currentMousePosition} /> : null}
      <div style={{ flex: 1, width: active ? 'calc(100% - 300px)' : '100%', zIndex: 1 }}>
        <Map
          maxBounds={[[-2.5, 55.6], [32.9, 72.9]]}
          ref={(el) => { this.map = el; }}
          center={center}
          onDragEnd={this.onDragEnd}
          onTouchEnd={this.onDragEnd}
          onZoomEnd={this.onZoomEnd}
          style="mapbox://styles/mapbox/streets-v8"
          onContextMenu={this.onRightClick}
          containerStyle={{
            height: '100%',
            minHeight: '100%',
            width: '100%'
          }}>
          <Layer
            type="circle"
            paint={{ "circle-color": "#116466", 'circle-radius': 15, 'circle-stroke-color': 'black', 'circle-stroke-width': 2, 'circle-opacity': 0.8 }}>
            {markers}
          </Layer>
          {active ?
            (<Popup
              onClick={() => this.handleClick(null)}
              style={{ maxWidth: 400, padding: 5 }}
              coordinates={active.location}
              anchor={'bottom'}>
              <Callout item={active} />
            </Popup>) : null
          }
        </Map>
        <div style={{ position: 'absolute', bottom: 15, left: 'calc(50% - 150px)', minHeight: 44, verticalAlign: 'middle', textAlign: 'center', width: 300, maxWidth: 300, minWidth: 300, backgroundColor: '#ffffff', padding: 5, fontSize: 12 }}>
          <p>Ønsker du skjule annonser? <Link to={ROUTES.MEMBERSHIP}>Les mer her</Link></p>
        </div>
      </div>
      {
        active ? <div style={{ flex: '0 1 100%', minWidth: 300, width: '100%', maxWidth: 500, backgroundColor: 'white' }}>
          <Details item={active} onClose={() => { this.handleClick(null) }} />
        </div> : null
      }
    </div >
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(NappMapPage);


const Callout = ({ item }) => (
  <div style={{ padding: 0 }}>
    <p>{item.title} {item.hc_friendly ? <NappIcon style={{ textAlign: 'right' }} title="HC-tilpasset" size={4} name="wheelchair" color="#007AFF" /> : null}</p>
  </div>
);

const Details = ({ item, onClose = () => { } }) => (
  <div style={{ textAlign: 'left', marginTop: 50, paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'middle' }}>
      <h2>{item.title}</h2>
      <div style={{ marginTop: 5 }}>
        <NappIcon style={{ cursor: 'pointer' }} onClick={() => onClose()} color="#282c34" size={10} name="window-close" />
      </div>
    </div>
    <div>
      {item.hc_friendly ? <NappIcon style={{ textAlign: 'right' }} size={6} title="HC-tilpasset" name="wheelchair" color="#007AFF" /> : null}
      <h3>Beskrivelse</h3>
      <p>{item.description}</p>
    </div>
    {!!item.species ? <div>
      <h3>Arter</h3>
      <ul>
        {item.species.map(s => <li key={s.id}>{s.name}</li>)}
      </ul>
    </div> : null}
    <div>
      <h3>Fremkomst og parkering</h3>
      <p>{item.arrival}</p>
    </div>
    <div>
      <h3>Fiskekort</h3>
      <p>{item.card}</p>
    </div>
    <div>
      <h3>Fiskeregler</h3>
      <p>{item.rules}</p>
    </div>
  </div>
)


class SearchBarComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = { searchText: '', data: [] };
    this.onChange = this.onChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  onChange(event) {
    const searchText = event.target.value;
    this.setState({ searchText });
  }

  async onSearch(event) {
    event.preventDefault();
    this.setState({ loading: true });
    try {
      const data = await this.props.firebase.searchForWaters(this.state.searchText.trim().toLowerCase());
      this.setState({ loading: false, data });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, data: [] });
    }
  }

  render() {
    const { searchText, loading, data } = this.state;
    const { onClick = () => { } } = this.props;
    return (
      <div className="search_bar">
        <input placeholder="Søk etter vann" value={searchText} onChange={this.onChange} />
        {data.length ? <div className="search_bar__results">
          {data.map(d => (
            <div className="search_bar__result-item" key={"search" + d.id} onClick={() => {
              onClick(d);
              this.setState({ data: [], searchText: '' });
            }}>
              {d.title}
            </div>
          ))}
        </div> : null}
        <button onClick={this.onSearch} disabled={loading} style={{ minWidth: 50 }} className="button button-primary button-small">{!loading ? 'Søk' : <i className="fa fa-spinner fa-spin" />}</button>
      </div>
    )
  }
}

const SearchBar = withFirebase(SearchBarComponent);

const ContextMenu = ({ position, onClick }) => (
  !!position ? <div className="map__context-menu" style={{ top: position.point.y, left: position.point.x }}>
    <div className="map__context-menu__content">
      <Link to={ROUTES.WATERS + "/ny?location=" + position.location.join(",")}>Opprett ny fiskeplass her</Link>
    </div>
    <NappButton title="Lukk" size="small" onClick={onClick} />
  </div> : null
)