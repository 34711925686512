import React from 'react';
import { withAuthorization } from '../session';
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import NavBar from '../components/navbar';
import { compose } from 'recompose';
import { NappButton } from '../components/button';
import * as ROUTES from '../routes';
import NMapComponent from '../components/map';
import { NappToaster } from '../components/toaster';
import { NappIcon } from '../components/icon';

const WatersPage = (props) => (
  <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', backgroundColor: 'white' }}>
    <NavBar />
    <WatersBaseComponent {...props} />
  </div>
);


class WatersBaseComponent extends React.Component {
  state = { data: [], loading: true };
  _isMounted = false;

  async componentDidMount() {
    this._isMounted = true;
    const waters = await this.props.firebase.getMyWaters();
    this.setState({ data: waters.filter(w => !!w.location), loading: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async deleteWater(id) {
    try {
      await this.props.firebase.deleteWater(id);
      NappToaster.show("Fiskeplassen ble slettet", "success");
      const { data } = this.state;
      const i = data.findIndex(d => d.id === id);
      data.splice(i, 1);
      this.setState({ data });
    } catch (e) {
      console.log(e);
      NappToaster.show("Kunne ikke slette fiskeplass", "error");
    }
  }

  render() {
    const { loading, data } = this.state;
    return (
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column', width: '100%', marginTop: 50 }}>
        <div style={{ marginBottom: 20 }}>
          <h1>Mine vann</h1>
          <div style={{ marginLeft: -5 }}>
            <NappButton style={{ minWidth: 220 }} title="+ Legg til ny fiskeplass" onClick={() => this.props.history.push(ROUTES.WATERS + "/ny")} />
          </div>
        </div>
        {loading ? <p>Henter oversikt...</p> : null}
        {!loading && !data.length ? <p>Du har ingen fiskeplasser. Opprett en da vel?</p> : null}
        {!loading && data.length ? <div className="responsive-container">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '65%' }}>Navn</th>
                <th style={{ width: '10%' }}>Båtutleie</th>
                <th style={{ width: '5%' }}>HC</th>
                <th style={{ width: '20%' }}></th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => <tr style={{ cursor: 'pointer' }} key={d.id} onClick={() => this.setState({ center: d.location })}>
                <td>{d.title}</td>
                <td style={{ textAlign: 'center' }}><NappIcon name={!!d.boat_rental ? 'check' : 'times'} color={!!d.boat_rental ? 'green' : 'red'} size={5} /></td>
                <td style={{ textAlign: 'center' }}>{d.hc_friendly ? <NappIcon name="wheelchair" color="#007AFF" size={5} /> : null}</td>
                <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <NappButton size="small" title="Rediger" onClick={() => this.props.history.push(ROUTES.WATERS + "/" + d.id)} />
                  <NappButton size="small" color="error" title="Slett" onClick={() => { if (window.confirm("Vil du slette?")) { this.deleteWater(d.id) } }} />
                </td>
              </tr>
              )}
            </tbody>
          </table>
        </div> : null}
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  withRouter
)(WatersPage);
