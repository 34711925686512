export const LANDING = '/';
export const SIGN_UP = '/ny-bruker';
export const SIGN_IN = '/logginn';
export const MAP = '/kart';
export const PROFILE = '/profil';
export const WATERS = '/vann';
export const EULA = '/eula';
export const WATER_DETAILS = '/vann/:id';
export const PASSWORD_FORGET = '/glemt-passord'
export const CHANGE_LOG = '/endringslogg';
export const MEMBERSHIP = '/medlemskap'