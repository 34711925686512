import React from 'react';


const NappEulaText = (props) => {
  return (<div>
    <p>
      <h3>1. Innhenting av personopplysninger</h3>
      Vi behandler de personopplysningene du har avgitt når du opprettet en brukerkonto. Kartposisjoner og GPS- bruk krever at du har avgitt samtykke til at vi behandler disse opplysningene. Vi får også opplysninger indirekte når du benytter vår app og kartfunksjoner</p>
    <p><h3>2. Dine rettigheter</h3>
      Du kan utøve dine rettigheter ved å sende en e-post til post@andmark.no.
      Du har krav på innsyn og kopi av alle opplysninger vi lagrer om deg, og du kan be om at disse rettes hvis det er feilaktige opplysninger som er lagret. Du kan også be om sletting av opplysninger.
      Du kan slette brukerkontoen din selv under "Min Profil". Alle vann du har lagret blir liggende, slik at brukere fremdeles kan få tilgang.
    </p>
    <p><h3>3. Personopplysninger</h3>
      Følgende personopplysninger blir lagret: Navn og epostadresse.
    </p>
    <p>
      <h3>4. Formål med behandlingen</h3>
      Opplysningene blir brukt til å identifisere brukere.
    </p>
    <p>
      <h3>5. Grunnlaget for behandlingen</h3>
      Fordi fiskeplasser blir knyttet opp mot den brukeren som oppretter det, må vi kunne identifisere brukere.
    </p>
    <p><h3>6. Utlevering av opplysninger til tredjeparter</h3>
      Vi vil ikke dele eller selge personopplysninger til andre, med mindre vi er rettslig forpliktet til det.
    </p>
    <p><h3>7. Informasjonssikkerhet</h3>
      Vi sikrer dine personopplysninger ved både fysisk og virtuell adgangs- og tilgangskontroll, samt ved kryptering av sensitive deler av avgitte opplysninger.
      </p>

  </div>
  )
}

const NappEula = (props) => (
  <div style={{ padding: 20 }}>
    <h1>Brukervilkår</h1>
    <NappEulaText />
  </div>
)

export default NappEula;