import React from 'react';
import { withAuthorization } from '../session';
import { withFirebase } from '../Firebase';
import firebase from 'firebase/app';
import { withRouter, Link } from 'react-router-dom';
import NavBar from '../components/navbar';
import { compose } from 'recompose';
import { NappButton } from '../components/button';
import NMapComponent from '../components/map';
import { NappToaster } from '../components/toaster';
import * as ROUTES from '../routes';
import { NappIcon } from '../components/icon';
import LocationHelper from '../locationhelpler';

const WaterDetailPage = (props) => (
  <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', backgroundColor: 'white' }}>
    <NavBar />
    <WaterDetailPageComponent {...props} />
  </div>
);


const defaultData = {
  title: '',
  description: '',
  arrival: '',
  rules: '',
  card: '',
  boat_rental: '',
  species: []
}

class WaterDetailPageComponent extends React.Component {
  state = { data: { ...defaultData }, loading: 'Laster...', markers: [], isNew: undefined };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.mapClick = this.mapClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const id = this.props.location.pathname.split('/')[2].replace('/', '');
    if (id !== 'ny') {
      try {
        const water = await this.props.firebase.getWater(id);
        if (!!water) {
          this.setState({ data: water, loading: false, center: water.location });
        } else {
          NappToaster.show("Fant ikke vann...", "error");
        }
      } catch (e) {
        NappToaster.show(e.message, "error");
        setTimeout(() => window.location.href = "/vann", 2000);
      }
    } else {
      try {
        let { search } = this.props.location;
        if (!!search && search.includes("?location=") !== -1) {
          search = search.replace("?location=", '');
          search = search.split(',');
          if (!!search && search.length === 2) {
            const lng = Number(search[0]);
            const lat = Number(search[1]);
            if (!isNaN(lng) && !isNaN(lat)) {
              defaultData.location = [lng, lat];
              this.props.history.push(ROUTES.WATERS + "/ny");
            }
          }
        } else {
          this.setState({ loading: 'Henter ut posisjonen din...' });
          const userLoc = await LocationHelper.getCurrentLocation();
          if (!!userLoc) {
            defaultData.location = userLoc;
            this.setState({ center: userLoc, loading: false });
          }
        }
        this.setState({ data: { ...defaultData }, loading: false });
      } catch (e) {
        NappToaster.show("En feil oppstod", "error");
        this.setState({ loading: false });
      }
    }

    this.setState({ isNew: id === 'ny' });
  }

  onChange = (event) => {
    const { data } = this.state;
    data[event.target.name] = event.target.value;
    this.setState({ data });
  }

  mapClick(map, event) {
    const { lat, lng } = event.lngLat;
    const { data } = this.state;
    data.location = [lng, lat];
    this.setState({ data });
  }

  async onSubmit(ev) {
    ev.preventDefault();
    const { data, isNew } = this.state;
    try {
      if (isNew) {
        data.user = this.props.firebase.getUserId();
        data.coordinates = new firebase.firestore.GeoPoint(data.location[0], data.location[1]);
        data.searchable = data.title.trim().toLowerCase();
        await this.props.firebase.createWater(data);
        this.setState({ isNew: false });
        setTimeout(() => window.location.href = ROUTES.WATERS, 2000);
      } else {
        data.coordinates = new firebase.firestore.GeoPoint(data.location[0], data.location[1]);
        data.searchable = data.title.trim().toLowerCase();
        await this.props.firebase.updateWater(data.id, data);
      }
      NappToaster.show(isNew ? "Opprettet!" : "Endringene ble lagret", "success");
    } catch (e) {
      console.log('e', e);
      NappToaster.show(isNew ? "Kunne ikke opprette fiskeplass" : "Kunne ikke lagre endringene", "error");
    }
  }

  render() {
    const { loading, data, isNew, center } = this.state;
    if (loading) {
      return (
        <div style={{ marginTop: 30, flex: 1, padding: 20, marginBottom: 50 }}>
          <p>{loading}</p>
        </div>
      )
    }

    return (
      <div style={{ marginTop: 30, flex: 1, padding: 20, marginBottom: 50 }}>
        <div>
          {isNew === true ? <h1>Opprett fiskeplass</h1> : null}
          {isNew === false ? <h1>Rediger fiskeplass</h1> : null}
          <div style={{ marginTop: 5, marginBottom: 20 }}><Link to={ROUTES.WATERS}>Tilbake</Link></div>
        </div>
        <form className="form" onSubmit={this.onSubmit} style={{ marginBottom: 100 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div className="form-group">
                <label>Navn <NappIcon name="info-circle" color="primary" size={4} /></label>
                <input
                  placeholder="Navn" name="title" value={data.title} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label>Beskrivelse</label>
                <textarea
                  rows={5}
                  placeholder="Beskrivelse" name="description" value={data.description} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label>Ankomst</label>
                <textarea
                  rows={5}
                  placeholder="Ankomst" name="arrival" value={data.arrival} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label>Fiskeregler</label>
                <textarea
                  rows={5}
                  placeholder="Fiskeregler" name="rules" value={data.rules} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label>Fiskekort</label>
                <textarea
                  rows={5}
                  placeholder="Fiskekort" name="card" value={data.card} onChange={this.onChange} />
              </div>
              <div className="form-group">
                <label>Båtutleie?</label>
                <textarea
                  rows={5}
                  placeholder="Info om båtutleie" name="boat_rental" value={data.boat_rental} onChange={this.onChange} />
              </div>
            </div>
            <div className="form-group" style={{ flex: 1, marginLeft: 20 }}>
              <label>Posisjon</label>
              <div style={{ flex: 1, maxHeight: 500 }}>
                <NMapComponent onClick={this.mapClick} markers={[data]} center={center} />
              </div>
            </div>
          </div>
          <div style={{ flex: 0 }}>
            <NappButton title="Lagre" type="submit" />
          </div>
        </form>
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  withRouter
)(WaterDetailPage);
